import * as React from "react";
import { sortColumn } from "./Table.module.scss";

export const SortHeader = ({
  title,
  onClick,
}: {
  title: string;
  onClick: Function;
}) => {
  const [toggleSort, setToggleSort] = React.useState(false);
  return (
    <div onClick={() => onClick(toggleSort)}>
      {title}
      <button className={sortColumn} onClick={() => setToggleSort(!toggleSort)}>
        {toggleSort ? <>&#9662;</> : <>&#9652;</>}
      </button>
    </div>
  );
};
