import React from "react";

import { navigate } from "gatsby";

import { PageProps } from "gatsby";

import { Layout, Container } from "components/layout";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";
import { SearchInput } from "components/common/SearchInput";
import { Table, TableFooter } from "components/common/Table";
import { useUsers } from "hooks/api";
import { formatDate } from "utils/format";
import { AdminSidebar } from "components/view/AdminSidebar";

interface AdminUsersPageProps extends PageProps {}

export default function AdminUsersPage(props: AdminUsersPageProps) {
  const { token } = useAuth();
  const users = useUsers();

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  return (
    <Layout>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <SearchInput onChange={users.onChangeSearch} delay={250} />
          <Table
            data={users.data}
            pageLength={10}
            columns={[
              {
                title: "Username",
                accesor: "username",
                render: (value, row) => (
                  <a href={`/administration/user/${row.id}`}>{value}</a>
                ),
              },
              { title: "Email", accesor: "email" },
              {
                title: "Name",
                accesor: (row) => `${row.last_name}, ${row.first_name}`,
              },
              {
                title: "Date Joined",
                accesor: (row) => formatDate(row.date_joined),
              },
              {
                title: "Status",
                accesor: (row) => (row.is_active ? "ACTIVE" : "BLOCKED"),
              },
              { title: "STAFF", accesor: (row) => (row.is_staff ? "YES" : "") },
            ]}
          >
            <TableFooter
              pageLength={10}
              page={Number(users.pagination?.page) || 0}
              totalRecords={Number(users.pagination?.totalRecords) || 0}
              onPageChange={users.onChangePage}
            />
          </Table>
        </Container>
      </Container>
    </Layout>
  );
}
