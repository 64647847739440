import * as React from "react";
import { disabled } from "./Table.module.scss";

export interface TableFooterProps {
  page: number;
  pageLength: number;
  totalRecords: number;
  onPageChange?: (page: number) => void;
}

export function TableFooter(props: TableFooterProps) {
  const { page, totalRecords, pageLength, onPageChange } = props;

  const hasNextPage = React.useMemo(() => {
    return (page + 1) * pageLength < totalRecords;
  }, [page, pageLength, totalRecords]);

  const hasPrevPage = React.useMemo(() => {
    return page > 0;
  }, [page, pageLength]);

  const handlePrevPage = React.useCallback(() => {
    if (hasPrevPage) {
      onPageChange?.(page - 1);
    }
  }, [page, hasPrevPage]);

  const handleNextPage = React.useCallback(() => {
    if (hasNextPage) {
      onPageChange?.(page + 1);
    }
  }, [page, hasNextPage]);

  return (
    <tfoot>
      <tr>
        <td colSpan={6}>
          <div>
            <button
              className={!hasPrevPage ? disabled : ""}
              onClick={handlePrevPage}
            >
              Prev
            </button>
            <button
              className={!hasNextPage ? disabled : ""}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}
